import Layout from "@layouts/VerticalLayout";
import { Outlet } from "react-router-dom";
import { getUserNavigation } from '../navigation/vertical/index';

const VerticalLayout = () => {
  return (
    <Layout menuData={getUserNavigation()}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
