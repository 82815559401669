import useJwt from "@src/auth/jwt/useJwt";
import * as actionTypes from "./types";

// ** List Cashier
export const setCompanyLogo = (data) => {
  return async (dispatch) => {
    try {
      const { status, data } = await useJwt.getCompanyDetailsPublic();
      if (status >= 200 && status < 300) {
        const { logoUrl } = data;
        dispatch({
          type: actionTypes.SET_COMPANY_LOGO,
          payload: logoUrl,
        });
      } else {
        dispatch({
          type: actionTypes.SET_COMPANY_LOGO,
          payload: "",
        });
      }
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.SET_COMPANY_LOGO,
          payload: "",
        });
    }
  };
};
