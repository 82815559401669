import { showSuccessMsgToast, showErrorMsgToast } from "../../utility/functions/common";

const ExportShift = (endPoint, fileName, data, setLoading) => {
    if (setLoading !== undefined) {
        setLoading(true);
    }
    const MAIN_SERVICE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_MAIN_SERVICE_URL : process.env.REACT_APP_MAIN_SERVICE_URL_DEV;
    const headers = new Headers();
    headers.append("Authorization", localStorage.getItem("accessToken")
        ? `Bearer ${localStorage.getItem("accessToken")}`
        : `Bearer ${localStorage.getItem("refreshToken")}`);
    headers.append("Content-Type", "application/json");

    fetch(`${MAIN_SERVICE_URL}/${endPoint}`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", 'cashier.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            if (setLoading !== undefined) {
                setLoading(false);
            }
            showSuccessMsgToast("SuccessFully export data");
        })
        .catch((e) => {
            if (setLoading !== undefined) {
                setLoading(false);
            }
            showErrorMsgToast("Export Data Failed");
        });
};

export default ExportShift;
