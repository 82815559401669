import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
import "prismjs";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/components/ripple-button";
import Spinner from "./@core/components/spinner/Fallback-spinner";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import ability from "./configs/acl/ability";
import themeConfig from "./configs/themeConfig";
import { store } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";

const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <Toaster
              position={themeConfig.layout.toastPosition}
              toastOptions={{ className: "react-hot-toast" }}
            />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </BrowserRouter>
);

serviceWorker.unregister();
