import * as actionTypes from "./types";

const initialState = {
  companyLogo: "",
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_LOGO: {
      return {
        ...state,
        companyLogo: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
