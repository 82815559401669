import { Link } from "react-router-dom";
import Avatar from "@components/avatar";
import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User, CheckSquare, Power } from "react-feather";
import { AbilityContext } from "@src/utility/context/Can";
import Profile from "../../../../assets/images/logo/Profile.png";
import { handleLogout } from "../../../../redux/auth/authActions";
import { handleGetProfile } from "../../../../redux/profile/profileActions";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import { CompanyIcon } from "../../../../assets/images/sidebar";
import {setCompanyLogo} from '../../../../redux/app/appActions';

const UserDropdown = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (admin) dispatch(handleGetProfile());
    dispatch(setCompanyLogo())
  }, [dispatch]);

  const restAbility = () => {
    ability.update([]);
  };
  const logoutHandler = () => {
    dispatch(handleLogout(restAbility));
  };

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {(profile && profile["name"]) || "Michel"}
          </span>
          <span className="user-status">
            {(admin && admin["role"]) === "superadmin"
              ? "Super Admin"
              : (admin && admin["role"]) === "admin" ? "Admin" : "Partner"}
          </span>
        </div>
        <Avatar img={Profile} imgHeight="40" imgWidth="40" status="online" />
      </DropdownToggle>
      <DropdownMenu end>
        {admin && admin["role"] === "superadmin" && (
          <DropdownItem tag={Link} to="/admin">
            <User size={14} className="me-75" />
            <span className="align-middle">Edit Profile</span>
          </DropdownItem>
        )}
        {admin && admin["role"] === "superadmin" && (
          <DropdownItem tag={Link} to="/password/update">
            <CheckSquare size={14} className="me-75" />
            <span className="align-middle">Edit Password</span>
          </DropdownItem>
        )}
        {admin && admin["role"] === "superadmin" && (
          <DropdownItem tag={Link} to="/company">
            {/* <CheckSquare size={14} className="me-75" /> */}
            <CompanyIcon className="me-75" />
            <span className="align-middle">Edit Company</span>
          </DropdownItem>
        )}

        <DropdownItem tag={Link} to="/login" onClick={logoutHandler}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
