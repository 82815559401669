import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { DefaultRoute } from "../router/routes";

export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num;
};

export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const formatDate = (value, formatting = { month: "short", day: "numeric", year: "numeric" }) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const isUserLoggedIn = () => localStorage.getItem("accessToken");

export const getUserData = () => {
  if (localStorage.accessToken) {
    return jwt_decode(localStorage.getItem("accessToken"));
  }
};

export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole) return DefaultRoute;
  return "/login";
};

export function NavigationHandler() {
  const history = useNavigate();
  history.push("/");
}

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const NumberFormatter = (price) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  }).format(price);
};

export const NumberFormatWithMinus = (data, price) => {
  const user = getUserData();
  if (user?.role === "admin") {
    if (
      data.location?.name === "Reuzenrad" ||
      data.location?.name === "Brute Friterie"
    ) {
      price = price;
    } else {
      price = price - (price * 10) / 100;
    }
  } else {
    price = price;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  }).format(price);
};

export const QuantityFormatter = (value) => {
  return new Intl.NumberFormat("en-US").format(value);
};
