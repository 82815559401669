import { getUserData } from '@utils';
import { AccessoriesBlue, AccessoriesWhite, Admin, Card, Cashier, Dash, Drink, Event, Location, Orders, Reports, Settings, ShiftIcon, SmartGateBlue, SmartGateWhite, TableBlue, TableWhite } from "../../assets/images/sidebar";
import { Partner } from '../../assets/images/sidebar/index';

export const SuperAdminNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Dash color="#808191" />,
    iconActive: <Dash color="#4263eb" />,
    action: "manage",
    resource: "superadmin",
    navLink: "/dashboard",
  },
  {
    id: "location",
    title: "Locations",
    action: "manage",
    resource: "superadmin",
    icon: <Location color="#808191" />,
    iconActive: <Location color="#4263eb" />,
    navLink: "/locations",
  },
  {
    id: "cashier",
    title: "Cashiers",
    icon: <Cashier color="#808191" />,
    iconActive: <Cashier color="#4263eb" />,
    action: "manage",
    resource: "superadmin",
    navLink: "/cashiers",
  },
  {
    id: "shifts",
    title: "Cashiers Shift",
    icon: <ShiftIcon color="#808191" />,
    iconActive: <ShiftIcon color="#4263eb" />,
    action: "manage",
    resource: "superadmin",
    navLink: "/shifts",
  },
  {
    id: "drink",
    title: "Drink/Food Products",
    icon: <Drink color="#808191" />,
    iconActive: <Drink color="#4263eb" />,
    children: [
      {
        id: "category",
        title: "Categories",
        action: "manage",
        resource: "superadmin",
        navLink: "/categories",
      },
      {
        id: "group",
        title: "Groups",
        action: "manage",
        resource: "superadmin",
        navLink: "/groups",
      },
      {
        id: "kitchen",
        title: "Kitchens",
        action: "manage",
        resource: "superadmin",
        navLink: "/kitchens",
      },
      {
        id: "products",
        title: "Products",
        action: "manage",
        resource: "superadmin",
        navLink: "/products",
      },
    ],
  },
  {
    id: "event",
    title: "Events",
    action: "manage",
    resource: "superadmin",
    icon: <Event color="#808191" />,
    iconActive: <Event color="#4263eb" />,
    navLink: "/events",
  },
  {
    id: "smartgate",
    title: "Smart Gates",
    action: "manage",
    resource: "superadmin",
    icon: <SmartGateWhite />,
    iconActive: <SmartGateBlue />,
    navLink: "/smartgate",
  },
  {
    id: "table",
    title: "Tables",
    action: "manage",
    resource: "superadmin",
    icon: <TableWhite />,
    iconActive: <TableBlue />,
    navLink: "/tables",
  },
  {
    id: "accessories",
    title: "Accessories",
    action: "manage",
    resource: "superadmin",
    icon: <AccessoriesWhite />,
    iconActive: <AccessoriesBlue />,
    navLink: "/accessories",
  },
  {
    id: "rfid",
    title: "Cash  Cards",
    icon: <Card color="#808191" />,
    iconActive: <Card color="#4263eb" />,
    children: [
      {
        id: "cards",
        title: "Cards",
        action: "manage",
        resource: "superadmin",
        navLink: "/cards",
      },
      {
        id: "refund",
        title: "Refunds",
        action: "manage",
        resource: "superadmin",
        navLink: "/refund",
      },
      {
        id: "refund-history",
        title: "Refunds History",
        action: "manage",
        resource: "superadmin",
        navLink: "/refund-history",
      },
      {
        id: "createrfids",
        title: "Bulk Cards",
        action: "manage",
        resource: "superadmin",
        navLink: "/createBulkCards",
      },
    ],
  },
  {
    id: "tickets",
    title: "Tickets",
    action: "manage",
    resource: "superadmin",
    icon: <Card color="#808191" />,
    iconActive: <Card color="#4263eb" />,
    navLink: "/ticketList",
  },
  {
    id: "orders",
    title: "Orders",
    icon: <Orders color="#808191" />,
    iconActive: <Orders color="#4263eb" />,
    children: [
      {
        id: "regular",
        title: "Regular Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/regularOrders",
      },
      {
        id: "regularorderexport",
        title: "Export Regular Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/regularOrderExport",
      },
      {
        id: "rfidorder",
        title: "RFID Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/rfidOrders",
      },
      {
        id: "rfidorderexport",
        title: "Export RFID Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/rfidOrdersexport",
      },
      {
        id: "freeze",
        title: "Freezed Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/freezedOrders",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <Reports color="#808191" />,
    iconActive: <Reports color="#4263eb" />,
    children: [
      {
        id: "hourlyproductsale",
        title: "Hourly Product Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/hourlproductsales",
      },
      {
        id: "productsale",
        title: "Product Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/productSales",
      },
      {
        id: "sales",
        title: " Location Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/salesReport",
      },
      {
        id: "sale",
        title: " Cashier Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/cashierSales",
      },
      {
        id: "productsReport",
        title: "Management Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/managementSales",
      },
    ],
  },
  /*{
    id: 'tool',
    title: "Correction Tool",
    icon: <Setting />,
    children: [
      {
        id: 'producttool',
        title: "Product Tool",
        action: 'manage',
        resource: 'superadmin',
        navLink: "/productTool"

      },
      {
        id: 'rfidtool',
        title: "RFID Tool",
        action: 'manage',
        resource: 'superadmin',
        navLink: "/RfidTool"

      }
    ]
  }, */
  {
    id: "admin",
    title: "Admins",
    action: "manage",
    resource: "superadmin",
    icon: <Admin color="#808191" />,
    iconActive: <Admin color="#4263eb" />,
    navLink: "/adminList",
  },
  {
    id: "partners",
    title: "Partners",
    action: "manage",
    resource: "superadmin",
    icon: <Partner color="#808191" />,
    iconActive: <Partner color="#4263eb" />,
    navLink: "/partnersList",
  },
  {
    id: "settings",
    title: "Settings",
    action: "manage",
    resource: "superadmin",
    icon: <Settings color="#808191" />,
    iconActive: <Settings color="#4263eb" />,
    navLink: "/settings",
  },
];

export const AdminNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Dash size={20} />,
    action: "read",
    resource: "admin",
    navLink: "/dashboard",
  },
  {
    id: "location",
    title: "Locations",
    icon: <Location size={20} />,
    action: "read",
    resource: "admin",
    navLink: "/locations",
  },
  {
    id: "cashier",
    title: "Cashiers",
    icon: <Cashier />,
    action: "read",
    resource: "admin",
    navLink: "/cashiers",
  },
  {
    id: "drink",
    title: "Drink/Food Products",
    icon: <Drink />,
    children: [
      {
        id: "category",
        title: "Categories",
        action: "read",
        resource: "admin",
        navLink: "/categories",
      },
      {
        id: "group",
        title: "Groups",
        action: "read",
        resource: "admin",
        navLink: "/groups",
      },
      {
        id: "kitchen",
        title: "Kitchens",
        action: "read",
        resource: "admin",
        navLink: "/kitchens",
      },
      {
        id: "products",
        title: "Products",
        action: "read",
        resource: "admin",
        navLink: "/products",
      },
    ],
  },
  {
    id: "event",
    title: "Events",
    action: "read",
    resource: "admin",
    icon: <Event />,
    navLink: "/events",
  },
];

export const PartnerNavigation = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Dash size={20} />,
    action: "read",
    resource: "partner",
    navLink: "/dashboard",
  },
  {
    id: "orders",
    title: "Orders",
    icon: <Orders color="#808191" />,
    iconActive: <Orders color="#4263eb" />,
    action: "read",
    resource: "partner",
    children: [
      {
        id: "regular",
        title: "Regular Orders",
        action: "read",
        resource: "partner",
        navLink: "/partner/regularOrders",
      },
      {
        id: "regularorderexport",
        title: "Export Regular Orders",
        action: "read",
        resource: "partner",
        navLink: "/partner/regularOrderExport",
      },
      {
        id: "rfidorder",
        title: "RFID Orders",
        action: "read",
        resource: "partner",
        navLink: "/partner/rfidOrders",
      },
      {
        id: "rfidorderexport",
        title: "Export RFID Orders",
        action: "read",
        resource: "partner",
        navLink: "/partner/rfidOrdersexport",
      },
      {
        id: "freeze",
        title: "Freezed Orders",
        action: "read",
        resource: "partner",
        navLink: "/partner/freezedOrders",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <Reports color="#808191" />,
    iconActive: <Reports color="#4263eb" />,
    children: [
      {
        id: "hourlyproductsale",
        title: "Hourly Product Sales",
        action: "read",
        resource: "partner",
        navLink: "/partner/hourlproductsales",
      },
      {
        id: "productsale",
        title: "Product Sales",
        action: "read",
        resource: "partner",
        navLink: "/partner/productSales",
      },
      {
        id: "sales",
        title: " Location Sales",
        action: "read",
        resource: "partner",
        navLink: "/partner/salesReport",
      },
      {
        id: "sale",
        title: " Cashier Sales",
        action: "read",
        resource: "partner",
        navLink: "/partner/cashierSales",
      }
    ],
  },
];

export const getUserNavigation = () => {
  const user = getUserData();
  if (user?.role === "superadmin") return SuperAdminNavigation
  if (user?.role === "admin") return AdminNavigation
  if (user?.role === "partner") return PartnerNavigation
}